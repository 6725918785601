.inscription-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .content {
        min-height: calc(100vh - 64px - 104px);
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        h1 {
            color: #233f6e;
            text-align: center;
            font-size: 3em;
            font-weight: 700;
        }

        .contain {
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                color: black;
                font-size: 20px;
                text-align: center;
                margin: 10px 0px;
            }

            a {
                color: #0061ca;
                font-size: 20px;

                &:hover {
                    text-decoration: underline;
                }
            }

            .deja {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                h2 {
                    color: #233f6e;
                    text-align: center;
                    font-size: 2em;
                    font-weight: 700;
                    margin: 10px 0px;
                }
            }

            .info {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 20px;

                h2 {
                    color: #233f6e;
                    text-align: center;
                    font-size: 2em;
                    font-weight: 700;
                    margin: 10px 0px;
                }
            }


        }
    }
}
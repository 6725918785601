.contain-home {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 78vh;
    position: sticky;
    top: 137px;

    .content {
        width: 90%;
        height: 80vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        background-image: url("../../images/gymnase.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .title {
            padding: 100px 0px 100px 0px;
            height: 85vh;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);

            h1 {
                font-size: 4em;
                font-weight: 400;
                color: black;
                text-align: center;
                margin-bottom: 30px;
            }

            a {
                background-color: #233f6e;
                padding: 15px 20px;
                color: white;
                transition-duration: 300ms;
            }

            a:hover {
                background-color: #233e6ebe;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .contain-home {
        .content .title {
            width: 100% !important;
        }
    }

}

@media screen and (max-width: 1000px) {
    .contain-home {
        .content {
            background-size: cover !important;
        }
    }
}

/*---- LOCALISATION ----*/

.localisation {
    position: relative;
    min-height: 77vh;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    padding-top: 100px;
    width: 100%;

    .title_localisation {
        font-size: 3em;
        color: #233f6e;
        text-align: center;
        font-weight: 700;
    }

    .content {
        padding-top: 20px;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            text-align: center;
            font-size: 18px;
            color: black;
            font-weight: bold;
        }

        iframe {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .localisation {
        .content {
            width: 85%;
        }
    }
}

@media screen and (max-width: 600px) {
    .localisation {
        .content {
            width: 95%;
        }
    }
}

/*---- SOCIAL ----*/

.social {
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 20px 30px 20px;
    width: 100%;

    h2 {
        font-size: 3em;
        color: #233f6e;
        text-align: center;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .partenaire {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .div {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 10px;

            a img {
                height: 120px;
            }

            p {
                font-size: 20px;
                color: black;
                font-weight: bold;
                text-align: center;
                margin: 5px 0px 15px 0px;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .social {
        .partenaire {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .div {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 10px;
            }
        }
    }
}

@media screen and (max-width: 510px) {
    .social {
        .partenaire {
            .div {
                a {
                    img {
                        height: 100px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .social {
        .partenaire {
            .div {
                a {
                    img {
                        height: 70px;
                    }
                }
            }
        }
    }
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
}

html,
body {
    min-height: 100%;
}

body {
    background: white;
    font-family: "Poppins", sans-serif;
}

li {
    list-style-type: none;
}

.mini-heading {
    font-size: 3vw;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .mini-heading {
        font-size: 5vw;
    }
}

@media screen and (max-width: 800px) {
    .mini-heading {
        font-size: 7vw;
    }
}

@media screen and (max-width: 400px) {
    .mini-heading {
        font-size: 10vw;
    }
}
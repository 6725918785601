footer {
    z-index: 9999;
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px 4vw;

    a {
        color: #2f2fb1;

        &:hover {
            text-decoration: underline;
        }
    }

    br {
        display: flex;
    }

    hr {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        br {
            display: none;
        }

        hr {
            display: flex;
            width: 35px;
            margin: 15px 0px;
            border: 0.5px solid gray;
        }
    }
}
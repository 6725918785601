.creneaux {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
        width: 100%;
        min-height: 81vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
            font-size: 3em;
            color: #233f6e;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
        }

        .contain-creneaux {
            margin: 20px 0px;
            width: 75%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .creneaux {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                position: relative;

                .creneaux-visible {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    padding: 0px 40px;
                    height: 114px;
                    background-color: #fff;
                    position: relative;
                    z-index: 10;
                    box-shadow: rgb(165 143 143 / 58%) 3px -3px 20px 0px;

                    .info {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        h2 {
                            color: black;
                            font-size: 2em;
                            font-weight: 500;
                        }

                        p {
                            color: black;
                            font-size: 17px;
                            font-weight: 400;
                        }
                    }

                    .btn {
                        button {
                            background-color: transparent;
                            cursor: pointer;
                            border: none;
                            font-size: 20px;
                            color: black;
                            font-weight: 400;
                            transition-duration: 300ms;
                        }

                        .click {
                            transform: rotate(540deg);
                            color: #3eadff;
                        }
                    }

                }

                .creneaux_content {
                    box-shadow: rgb(165 143 143 / 58%) 3px -3px 20px 0px;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    position: absolute;
                    z-index: 1;
                    right: -1%;
                    transition-timing-function: ease-in-out;
                    height: 114px;

                    .day {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        h3 {
                            color: black;
                            font-size: 17px;
                            font-weight: 700;
                        }

                        .add-info {
                            color: orangered;
                            font-weight: 600;
                            font-size: 17px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

/*---- RESPONSIVE ----*/

@media screen and (max-width: 1500px) {
    .creneaux .content .contain-creneaux {
        margin: 20px 0px;
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .creneaux .content .contain-creneaux .creneaux {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
    }

    .creneaux .content .contain-creneaux .creneaux .creneaux_content {
        margin-top: 10px;
        box-shadow: rgb(165 143 143 / 58%) 3px -3px 20px 0px;
        right: 0%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 0px 40px;
        height: 114px;
        background-color: #fff;
        position: relative;
        z-index: 10;
    }

    .creneaux .content .contain-creneaux .creneaux .creneaux-visible {
        margin-top: 10px;
        box-shadow: rgb(165 143 143 / 58%) 3px -3px 20px 0px;
        right: 0%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 0px 40px;
        height: 114px;
        background-color: #fff;
        position: relative;
        z-index: 10;
    }
}

@media screen and (max-width: 950px) {
    .creneaux {
        .content {
            width: 100%;

            .contain-creneaux {
                margin: 20px 0px;
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                .creneaux {

                    .creneaux_content {
                        margin-top: 10px;
                        box-shadow: rgb(165 143 143 / 58%) 3px -3px 20px 0px;
                        right: 0%;
                        width: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 0px 40px;
                        height: auto;
                        background-color: #fff;
                        position: relative;
                        z-index: 10;

                        .day {
                            width: auto;
                            min-height: 114px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                        }
                    }

                    .creneaux-visible {
                        margin-top: 10px;
                        box-shadow: rgb(165 143 143 / 58%) 3px -3px 20px 0px;
                        right: 0%;
                        width: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 0px 40px;
                        height: auto;
                        min-height: 114px;
                        background-color: #fff;
                        position: relative;
                        z-index: 10;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .creneaux {
        .content {
            .contain-creneaux {
                .creneaux {
                    .creneaux_content {
                        width: 80%;
                    }

                    .creneaux-visible {
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .creneaux {
        .content {
            .contain-creneaux {
                .creneaux {
                    .creneaux_content {
                        width: 95%;
                    }

                    .creneaux-visible {
                        width: 95%;
                    }
                }
            }
        }
    }
}
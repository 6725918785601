.header {
    z-index: 1000;
    position: sticky;
    top: 0px;
    background-color: rgb(255 255 255 / 59%);
    backdrop-filter: blur(4px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5%;

    .left {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            position: relative;
            background-color: transparent;
        }

        a::before {
            top: -55px;
            content: '';
            position: absolute;
            width: 170px;
            height: 110px;
            background-image: url(../../images/logo/logo-sert-volant-no-text.png);
            overflow: visible;
            background-position: left;
            background-size: 90%;
            background-repeat: no-repeat;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: black;
            font-size: 17px;
            text-align: center;
            font-weight: 400;
            margin: 17px;
        }

        a.facebook-btn {
            outline: none;
            text-decoration: none;
            background-color: #233f6e;
            color: white;
            font-size: 20px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition-duration: 300ms;

            &:hover {
                background-color: #2b4d86;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .header {
        .left {
            visibility: hidden !important;
        }
    }
}

@media screen and (max-width: 440px) {
    .header {
        .right {
            a {
                margin: 17px 10px;
            }
        }
    }
}

@media screen and (max-width: 370px) {
    .header {
        .right {
            a {
                margin: 17px 7px;
            }
        }
    }
}
.page-404 {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
        height: 75vh;
        display: flex;
        align-items: center;
        flex-direction: column;

        h1 {
            color: #ef2845;
            font-size: 4em;
            text-align: center;
            font-weight: 400;
            margin-bottom: 30px;
        }

        h3 {
            color: #69757c;
            font-size: 3em;
            text-align: center;
            font-weight: 400;
            margin-bottom: 30px;
        }

        p {
            color: #69757c;
            font-size: 24px;
            text-align: center;
            margin-bottom: 10px;

            a {
                color: #0054af;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}